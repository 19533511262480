var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("PageTitleSearch", {
        attrs: {
          "page-title": !_vm.$language ? "Notes" : "লেকচার",
          placeholder: !_vm.$language
            ? "Search By Title"
            : "লেকচারের শিরোনাম দিয়ে সার্চ করো",
        },
        on: { searchElenent: _vm.searchLecture },
      }),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _vm.fetchingContent
                ? _c(
                    "v-col",
                    [
                      [
                        _c("div", { attrs: { id: "loading-wrapper" } }, [
                          _c("div", { attrs: { id: "loading-text" } }, [
                            _vm._v("LOADING"),
                          ]),
                          _c("div", { attrs: { id: "loading-content" } }),
                        ]),
                      ],
                    ],
                    2
                  )
                : _vm.noLectureCondition
                ? _c(
                    "v-col",
                    [
                      _c("no-content-component", {
                        attrs: { message: _vm.noLectureMessage },
                      }),
                    ],
                    1
                  )
                : _vm._l(_vm.filterLectures, function (lecture, i) {
                    return _c(
                      "v-col",
                      { key: i, attrs: { cols: _vm.colsNum } },
                      [
                        _c("m-lecture-list-item", {
                          attrs: { lecture: lecture },
                          on: {
                            download: function ($event) {
                              return _vm.download(lecture.object_id)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }