<template>
  <v-container>
    <PageTitleSearch
      :page-title="!$language ? 'Notes' : 'লেকচার'"
      :placeholder="
        !$language
          ? 'Search By Title'
          : 'লেকচারের শিরোনাম দিয়ে সার্চ করো'
      "
      @searchElenent="searchLecture"
    ></PageTitleSearch>
    <v-container>
      <v-row>
        <v-col v-if="fetchingContent">
          <template>
            <div id="loading-wrapper">
              <div id="loading-text">LOADING</div>
              <div id="loading-content"></div>
            </div>
          </template>
        </v-col>
        <v-col v-else-if="noLectureCondition">
          <no-content-component
            :message="noLectureMessage"
          ></no-content-component>
        </v-col>
        <v-col
          v-else
          v-for="(lecture, i) in filterLectures"
          :key="i"
          :cols="colsNum"
        >
          <m-lecture-list-item
            :lecture="lecture"
            @download="download(lecture.object_id)"
          ></m-lecture-list-item>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import lectureService from "#ef/lecture/services/LectureService";
import PageTitleSearch from "@ef/global/components/PageTitleSearch.vue";
import MLectureListItem from "#ef/lecture/components/MLectureListItem";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "MLectureList",
  components: { MLectureListItem, PageTitleSearch, NoContentComponent },
  data() {
    return {
      saving: false,
      batch_id: undefined,
      lectures: [],
      searchInput: "",
      fetchingContent: undefined,
    };
  },
  computed: {
    currentWidth() {
      return this.$vuetify.breakpoint.width;
    },
    colsNum() {
      return this.currentWidth < 800
        ? 12
        : this.currentWidth < 1280
        ? 12
        : this.currentWidth < 1920
        ? 6
        : 4;
    },
    filterLectures() {
      return this.lectures.filter((lecture) =>
        lecture.title.toLowerCase().match(this.searchInput.toLowerCase())
      );
    },
    noLectureCondition() {
      return this.lectures === undefined || this.lectures.length == 0;
    },
    noLectureMessage() {
      return !this.$language
        ? "You currently have no lectures"
        : "বর্তমানে আপনার কোনো লেকচার সমূহ নেই";
    },
  },
  created() {
    this.batch_id = this.$ielts_batch_id;
    const isOnline = window.navigator.onLine;
    if ( !isOnline ){
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
      return;
    }
    this.fetchLectures();
  },
  methods: {
    async fetchLectures() {
      try {
        this.fetchingContent = true;
        this.lectures = await lectureService.find(this.batch_id);
      } catch (e) {
        this.$root.$emit('alert', [undefined, e.message]);
      } finally {
        this.fetchingContent = false;
      }
    },
    searchLecture(searchInput) {
      this.searchInput = searchInput;
    },
    async download(lecture_id) {
      try {
        const lecture = await lectureService.get(lecture_id, this.batch_id);
        window.open(lecture.url);
      } catch (e) {
        this.$root.$emit("alert", ["Alert", e.toString()]);
      }
    },
  },
};
</script>
